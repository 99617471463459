<template>
  <div class="article-container">
    <el-card class="filter-card">
      <!-- 数据筛选表单 -->
      <el-form ref="form" label-width="80px" size="mini">
        <div class="flexparents">
          开始日期:
          <el-date-picker
            value-format="yyyy-MM-dd 00:00:00"
            v-model="form.createTimeAfter"
            type="date"
            placeholder="选择开始日期"
          >
          </el-date-picker>
          结束日期:
          <el-date-picker
            value-format="yyyy-MM-dd 23:59:59"
            v-model="form.creatTimeBefore"
            type="date"
            placeholder="选择结束日期"
          >
          </el-date-picker>

          <el-button
            class="leftgap"
            type="primary"
            :disabled="loading"
            @click="cleardata()"
            >清空</el-button
          >
          <el-button type="primary" :disabled="loading" @click="loadArticles(1)"
            >查询</el-button
          >

          <el-button
            type="info"
            :disabled="loading"
            @click="ifgaoji = !ifgaoji"
            >{{ ifgaoji ? "收起" : "高级查询" }}</el-button
          >
        </div>
        <div v-if="ifgaoji" class="flexparents zdhh">
          <div>
            客诉单号:
            <el-input
              class="input rightgap"
              v-model="form.complaintOrderNo"
              placeholder="请输入客诉单号"
            ></el-input>
          </div>
          <div>
            工单号:
            <el-input
              class="input rightgap"
              v-model="form.workOrderNo"
              placeholder="请输入工单号"
            ></el-input>
          </div>

          <div>
            {{ $t("faeOperatorName") }}
            <el-input
              class="input rightgap"
              v-model="form.faeOperatorName"
              :placeholder="this.$t('inputText')"
            ></el-input>
          </div>
          <div>
            {{ $t("customerName") }}
            <el-input
              class="input rightgap"
              v-model="form.customerName"
              :placeholder="this.$t('inputText')"
            ></el-input>
          </div>
        </div>
      </el-form>
      <!-- /数据筛选表单 -->
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        根据筛选条件共查询到 {{ totalCount }} 条结果：
      </div>

      <el-table
        ref="el_table"
        :data="articles"
        :height="tableHeight"
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column
          prop="complaintOrderNo"
          min-width="160"
          label="品质报告号"
          key="complaintOrderNo"
        >
        </el-table-column>

        <el-table-column
          prop="orderNo"
          min-width="160"
          label="原工单号"
          key="orderNo"
        >
        </el-table-column>

        <el-table-column
          min-width="160"
          prop="productModelType"
          :label="this.$t('productModelType')"
          key="productModelType"
        ></el-table-column>

        <el-table-column
          min-width="160"
          prop="complaintLevelType"
          label="客诉等级"
          key="complaintLevelType"
        >
          <template slot-scope="scope">  
            {{
              LeveLoptions[scope.row.complaintLevelType] &&
              LeveLoptions[scope.row.complaintLevelType].label
            }}
          </template>
        </el-table-column>

        <el-table-column
          min-width="160"
          prop="createdTime"
          label="创建时间"
          key="createdTime"
        >
        </el-table-column>

        <el-table-column
          min-width="160"
          prop="reportDate"
          label="报告日期"
          key="reportDate"
        >
        </el-table-column>

        <el-table-column
          min-width="160"
          prop="personLiable"
          label="研发跟进人"
          key="personLiable"
        >
        </el-table-column>

        <el-table-column
          min-width="160"
          prop="faeOperatorName"
          label="FAE处理人"
          key="faeOperatorName"
        >
        </el-table-column>

        <el-table-column
          min-width="160"
          prop="productType"
          label="产品类型"
          key="productType"
        >
          <template v-slot="scope">
            {{
              prodectoptions[scope.row.productType] &&
              prodectoptions[scope.row.productType].label
            }}
          </template>
        </el-table-column>

        <el-table-column
          min-width="160"
          prop="area"
          :label="this.$t('area')"
          key="area"
        >
          <template v-slot="scope">
            {{
              areaoptions[scope.row.area] && areaoptions[scope.row.area].label
            }}
          </template>
        </el-table-column>
        <el-table-column
          min-width="200"
          prop="customerName"
          :label="this.$t('customerName')"
          key="customerName"
        ></el-table-column>
        <el-table-column
          prop="salesman"
          :label="this.$t('salesman')"
          key="salesman"
        ></el-table-column>
        <el-table-column
          min-width="160"
          prop="type"
          :label="this.$t('type')"
          key="type"
        >
          <template v-slot="scope">
            {{
              problemtypeaoptions[scope.row.type] &&
              problemtypeaoptions[scope.row.type].label
            }}
          </template>
        </el-table-column>

        <el-table-column width="50" :label="$t('operate')" fixed="right">
          <template slot-scope="scope">
            <div>
              <!-- <el-tooltip
                :content="$t('lookAndDo')"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="addplan(scope.row)"
                  circle
                  icon="el-icon-search"
                  type="primary"
                ></el-button>
              </el-tooltip> -->

              <el-tooltip
                content="编辑报告"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="editreport(scope.row)"
                  circle
                  icon="el-icon-edit"
                  type="primary"
                ></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="sizes,prev, pager, next"
        background
        :total="totalCount"
        :page-sizes="[10, 20, 50, 100]"
        @size-change="handleSizeChange"
        :page-size="form.size"
        :disabled="loading"
        :current-page.sync="form.page"
        @current-change="onCurrentChange"
      />
      <!-- /列表分页 -->
    </el-card>

    <el-dialog :visible.sync="ifeditreport" append-to-body width="1100px">
      <el-dialog
        width="900px"
        :title="'编辑D' + EditDNum"
        :visible.sync="ifeditD"
        append-to-body
      >
        <template class="flexparents">
          <div style="border: 1px solid #ccc">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 500px; overflow-y: hidden"
              v-model="html"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
              @onChange="editChange"
            />
          </div>
        </template>

        <el-button
          style="margin-top: 10px"
          type="primary"
          :disabled="loading"
          @click="saveDEdit()"
          >保存</el-button
        >
      </el-dialog>
      <el-dialog
        width="400px"
        title="编辑基础信息"
        :visible.sync="ifeditbasic"
        append-to-body
      >
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="标题">
            <el-input v-model="baseEditDate.title"></el-input>
          </el-form-item>
          <el-form-item label="产品类型">
            <el-select v-model="baseEditDate.productType" placeholder="请选择">
              <el-option
                v-for="item in prodectoptions"
                :key="item.value"
                :label="item.label"
                :value="parseInt(item.value)"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品型号">
            <el-input v-model="baseEditDate.productModelType"></el-input>
          </el-form-item>
          <el-form-item label="报告日期">
            <el-date-picker
              v-model="baseEditDate.reportDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择开始日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <el-button type="primary" size="small" @click="editReport(0)"
          >确定</el-button
        >
      </el-dialog>

      <div id="pdfContainer" ref="pdfContainer" class="pdfContainer">
        <div class="titleParents">
          <div class="one">
            <div>
              <img src="@/assets/img/jimilogo.png" width="180" />
            </div>
            <div>深圳几米物联有限公司</div>
          </div>
          <hr
            style="border: none; border-top: 1px solid black; margin: 10px 0"
          />
          <div class="two">产品异常8D改善报告</div>
          <div class="three">
            <span>文件编号：</span
            ><span
              style="font-size: 15px; color: #000000; font-weight: bolder"
              >{{ ReportInfo.complaintOrderNo }}</span
            >
          </div>
        </div>

        <el-descriptions title="基础信息:" class="Editbody" :column="2" border>
          <template slot="extra">
            <el-button
              v-if="bianji"
              type="primary"
              size="small"
              @click="editbasic"
              >编辑</el-button
            >
          </template>
          <el-descriptions-item :span="2">
            <template slot="label"> 标题 </template>
            {{ ReportInfo.title }}
          </el-descriptions-item>

          <el-descriptions-item :span="1">
            <template slot="label"> 产品类型 </template
            >{{
              prodectoptions[ReportInfo.productType] &&
              prodectoptions[ReportInfo.productType].label
            }}</el-descriptions-item
          >

          <el-descriptions-item :span="1">
            <template slot="label"> 产品型号 </template>
            {{ ReportInfo.productModelType }}
          </el-descriptions-item>

          <el-descriptions-item :span="1">
            <template slot="label"> 发生日期 </template>
            {{ ReportInfo.occurredDate }}
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template slot="label"> 报告日期 </template>
            {{ ReportInfo.reportDate }}
          </el-descriptions-item>
        </el-descriptions>

        <p style="margin-bottom: 30px">
          备注：此品质报告是根据原工单号为 {{ ReportInfo.orderNo }} 的工单生成。
        </p>

        <el-descriptions title="D1成立团队:" :column="2" border>
          <template slot="extra">
            <el-button
              type="primary"
              v-if="bianji"
              size="small"
              @click="openEditD(1)"
              >编辑</el-button
            >
          </template>
        </el-descriptions>
        <div class="editclass">
          <Editor
            v-model="DHTMLDATA[0]"
            :defaultConfig="editorConfigbyDataD"
            :mode="mode"
            @onCreated="onCreated"
            @onChange="editChange"
            @onDestroyed="onDestroyed"
            @onMaxLength="onMaxLength"
            @onFocus="onFocus"
            @onBlur="onBlur"
            @customAlert="customAlert"
            @customPaste="customPaste"
          />
        </div>

        <el-descriptions title="D2问题描述:" :column="2" border>
          <template slot="extra">
            <el-button
              type="primary"
              v-if="bianji"
              size="small"
              @click="openEditD(2)"
              >编辑</el-button
            >
          </template>
        </el-descriptions>
        <div class="editclass">
          <Editor
            v-model="DHTMLDATA[1]"
            :defaultConfig="editorConfigbyDataD"
            :mode="mode"
            @onCreated="onCreated"
            @onChange="editChange"
          />
        </div>

        <el-descriptions title="D3临时对策:" :column="2" border>
          <template slot="extra">
            <el-button
              type="primary"
              v-if="bianji"
              size="small"
              @click="openEditD(3)"
              >编辑</el-button
            >
          </template>
        </el-descriptions>
        <div class="editclass">
          <Editor
            v-model="DHTMLDATA[2]"
            :defaultConfig="editorConfigbyDataD"
            :mode="mode"
            @onCreated="onCreated"
            @onChange="editChange"
          />
        </div>

        <el-descriptions title="D4根本原因分析:" :column="2" border>
          <template slot="extra">
            <el-button
              type="primary"
              v-if="bianji"
              size="small"
              @click="openEditD(4)"
              >编辑</el-button
            >
          </template>
        </el-descriptions>
        <div class="editclass">
          <Editor
            v-model="DHTMLDATA[3]"
            :defaultConfig="editorConfigbyDataD"
            :mode="mode"
            @onCreated="onCreated"
            @onChange="editChange"
          />
        </div>

        <el-descriptions title="D5永久对策:" :column="2" border>
          <template slot="extra">
            <el-button
              type="primary"
              v-if="bianji"
              size="small"
              @click="openEditD(5)"
              >编辑</el-button
            >
          </template>
        </el-descriptions>
        <div class="editclass">
          <Editor
            v-model="DHTMLDATA[4]"
            :defaultConfig="editorConfigbyDataD"
            :mode="mode"
            @onCreated="onCreated"
            @onChange="editChange"
          />
        </div>
        <el-descriptions title="D6对策有效性验证:" :column="2" border>
          <template slot="extra">
            <el-button
              type="primary"
              v-if="bianji"
              size="small"
              @click="openEditD(6)"
              >编辑</el-button
            >
          </template>
        </el-descriptions>
        <div class="editclass">
          <Editor
            v-model="DHTMLDATA[5]"
            :defaultConfig="editorConfigbyDataD"
            :mode="mode"
            @onCreated="onCreated"
            @onChange="editChange"
          />
        </div>

        <el-descriptions title="D7预防再发措施:" :column="2" border>
          <template slot="extra">
            <el-button
              type="primary"
              v-if="bianji"
              size="small"
              @click="openEditD(7)"
              >编辑</el-button
            >
          </template>
        </el-descriptions>
        <div class="editclass">
          <Editor
            v-model="DHTMLDATA[6]"
            :defaultConfig="editorConfigbyDataD"
            :mode="mode"
            @onCreated="onCreated"
            @onChange="editChange"
          />
        </div>

        <el-descriptions title="D8后续情况跟进:" :column="2" border>
          <template slot="extra">
            <el-button
              type="primary"
              v-if="bianji"
              size="small"
              @click="openEditD(8)"
              >编辑</el-button
            >
          </template>
        </el-descriptions>
        <div class="editclass">
          <Editor
            v-model="DHTMLDATA[7]"
            :defaultConfig="editorConfigbyDataD"
            :mode="mode"
            @onCreated="onCreated"
            @onChange="editChange"
          />
        </div>
      </div>

      <div class="mid">
        <el-button type="primary" size="small" @click="creatPDF()"
          >生成 pdf文件</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      @closed="loadArticles"
      title="查看详情"
      :visible.sync="planVisable"
      append-to-body
      width="1100px"
    >
      <el-steps
        :active="steptype"
        finish-status="success"
        class="gap"
        style="padding-left: 16px; padding-right: 16px"
      >
        <el-step title="开始" :description="FAEdatile.createdTime"></el-step>
        <el-step
          title="FAE处理中"
          :description="FAEdatile.firstBrowseTime"
        ></el-step>
        <el-step
          title="转发至研发"
          :description="yanfadatile.createdTime"
        ></el-step>
        <el-step
          title="研发处理中"
          :description="yanfadatile.firstBrowseTime"
        ></el-step>
        <el-step
          title="研发处理完成"
          :description="yanfadatile.completedTime"
        ></el-step>
        <el-step
          title="FAE确认完成中"
          :description="yanfadatile.completedTime"
        ></el-step>
        <el-step title="完成" :description="FAEdatile.completedTime"></el-step>
      </el-steps>
      <div class="destitle">基本信息</div>
      <el-descriptions class="desbasebody" :column="3">
        <el-descriptions-item label="订单号">{{
          FAEdatile.orderNo
        }}</el-descriptions-item>
        <el-descriptions-item label="型号">{{
          FAEdatile.productModelType
        }}</el-descriptions-item>
        <el-descriptions-item label="大区">{{
          areaoptions[FAEdatile.area] && areaoptions[FAEdatile.area].label
        }}</el-descriptions-item>
        <el-descriptions-item label="客诉类型">{{
          problemtypeaoptions[FAEdatile.type] &&
          problemtypeaoptions[FAEdatile.type].label
        }}</el-descriptions-item>
        <el-descriptions-item label="涉及平台">{{
          pingtaioptions[FAEdatile.platform] &&
          pingtaioptions[FAEdatile.platform].label
        }}</el-descriptions-item>
        <el-descriptions-item label="紧急程度">
          {{ urgentStatustext[FAEdatile.urgentType] }}
        </el-descriptions-item>
        <el-descriptions-item label="FAE处理人">{{
          FAEdatile.faeOperatorName
        }}</el-descriptions-item>
        <el-descriptions-item label="研发跟进人">{{
          FAEdatile.personLiable
        }}</el-descriptions-item>
        <el-descriptions-item label="创建人">{{
          FAEdatile.createdName
        }}</el-descriptions-item>
      </el-descriptions>

      <div class="desbody" v-if="this.esservedatiledata.orderNo != null">
        <el-descriptions :column="4">
          <el-descriptions-item label="IMEI">{{
            esservedatiledata.imei
          }}</el-descriptions-item>
          <el-descriptions-item label="平台/APP">{{
            esservedatiledata.platform
          }}</el-descriptions-item>
          <el-descriptions-item label="设备状态">{{
            esservedatiledata.deviceStatus
          }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{
            esservedatiledata.createdTime
          }}</el-descriptions-item>
          <el-descriptions-item label="购买渠道">{{
            esservedatiledata.channel
          }}</el-descriptions-item>
          <el-descriptions-item label="国家">{{
            esservedatiledata.country
          }}</el-descriptions-item>
          <el-descriptions-item label="其他联系方式">{{
            esservedatiledata.otherContactInfo
          }}</el-descriptions-item>
          <el-descriptions-item label="设备LED灯状态">{{
            esservedatiledata.ledStatus
          }}</el-descriptions-item>
          <el-descriptions-item label="SIM卡运营商">{{
            esservedatiledata.simCardOperator
          }}</el-descriptions-item>
          <el-descriptions-item label="使用场景">{{
            esservedatiledata.usageScenario
          }}</el-descriptions-item>
          <el-descriptions-item label="安装的车型号">{{
            esservedatiledata.installedVehicleModel
          }}</el-descriptions-item>
          <el-descriptions-item label="Email">{{
            esservedatiledata.email
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item label="问题描述" :span="3">
            <p style="white-space: pre-line">
              {{ esservedatiledata.description }}
            </p>
          </el-descriptions-item>

          <el-descriptions-item label="图片" :span="16">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              v-for="{ url } in esservedatiledata.image"
              :key="url"
              :src="url"
              :previewSrcList="ESpreviewList"
            />
          </el-descriptions-item>
          <el-descriptions-item label="附件" :span="16">
            <p v-for="{ url, path } in esservedatiledata.attachment" :key="url">
              <a :href="url" target="_blank">{{ path }}</a>
            </p>
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div class="destitle" v-if="this.kfdatile.orderNo != null">客服详情</div>

      <div class="desbody" v-if="this.kfdatile.orderNo != null">
        <el-descriptions :column="3">
          <el-descriptions-item label="客服">{{
            kfdatile.createdName
          }}</el-descriptions-item>
          <el-descriptions-item label="客户">{{
            kfdatile.customerName
          }}</el-descriptions-item>
          <el-descriptions-item label="客户手机号">{{
            kfdatile.phoneNumber
          }}</el-descriptions-item>
          <el-descriptions-item label="客诉标题">{{
            kfdatile.title
          }}</el-descriptions-item>
          <el-descriptions-item label="问题原因">{{
            kfdatile.reason
          }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{
            kfdatile.createdTime
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item label="问题描述" :span="3">
            <p style="white-space: pre-line">
              {{ kfdatile.description }}
            </p>
          </el-descriptions-item>
          <el-descriptions-item label="备注" :span="3">{{
            kfdatile.remark
          }}</el-descriptions-item>
          <el-descriptions-item label="图片" :span="3">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              fit="fit"
              v-for="{ url } in kfdatile.image"
              :key="url"
              :src="url"
              :previewSrcList="KFpreviewList"
            />
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div class="destitle">FAE详情</div>

      <div class="desbody">
        <el-descriptions :column="3">
          <el-descriptions-item label="关键字">{{
            FAEdatile.keyword
          }}</el-descriptions-item>
          <el-descriptions-item label="当前卡点">{{
            difoptions[FAEdatile.stuckPoint] &&
            difoptions[FAEdatile.stuckPoint].label
          }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{
            FAEdatile.createdTime
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item label="问题处理方案" :span="3">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in solutionlinedata"
                :key="index"
                :timestamp="activity.time"
              >
                <p style="white-space: pre-line">
                  {{ activity.detail }}
                </p>
              </el-timeline-item>
            </el-timeline>
          </el-descriptions-item>
          <el-descriptions-item label="描述" :span="3">
            <p style="white-space: pre-line">
              {{ FAEdatile.description }}
            </p>
          </el-descriptions-item>
          <el-descriptions-item label="客户原话/其他" :span="3">{{
            FAEdatile.customerDescription
          }}</el-descriptions-item>
          <el-descriptions-item label="图片" :span="3">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              fit="fit"
              v-for="{ url } in FAEdatile.image"
              :key="url"
              :src="url"
              :previewSrcList="FAEpreviewList"
            />
          </el-descriptions-item>

          <el-descriptions-item label="附件" :span="3">
            <p v-for="{ url, path } in FAEdatile.attachment" :key="url">
              <a :href="url" target="_blank">{{ path }}</a>
            </p>
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div class="destitle" v-if="this.yanfadatile.orderNo != null">
        研发详情
      </div>

      <div class="desbody" v-if="this.yanfadatile.orderNo != null">
        <el-descriptions :column="3">
          <el-descriptions-item :label="$t('createdTime')">{{
            yanfadatile.createdTime
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('zhuangtai')">{{
            attributeoptions[yanfadatile.attribute] &&
            attributeoptions[yanfadatile.attribute].label
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('personLiable')">{{
            yanfadatile.personLiable
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item label="解释说明" :span="3">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in remakelinedate"
                :key="index"
                :timestamp="activity.time"
              >
                {{ activity.detail }}
              </el-timeline-item>
            </el-timeline>
          </el-descriptions-item>

          <el-descriptions-item :label="$t('tapdlianjie')" :span="3">{{
            yanfadatile.tapdLink
          }}</el-descriptions-item>

          <el-descriptions-item :label="$t('yfkd')" :span="3">{{
            yanfadatile.developStuckPoint
          }}</el-descriptions-item>

          <el-descriptions-item :label="$t('jjfangan')" :span="3">{{
            yanfadatile.solution
          }}</el-descriptions-item>

          <el-descriptions-item label="图片" :span="3">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              fit="fit"
              v-for="{ url } in yanfadatile.imageInfos"
              :key="url"
              :src="url"
              :previewSrcList="YFpreviewList"
            />
          </el-descriptions-item>

          <el-descriptions-item label="附件" :span="3">
            <p v-for="{ url, path } in yanfadatile.fileInfos" :key="url">
              <a :href="url" target="_blank">{{ path }}</a>
            </p>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  SearchOneComplaintOrderUrl,
  getReportInfoUrl,
  editReportUrl,
  getreportPage,
} from "@/api/complant";
import {
  productModelTypeOptions,
  areaoptions,
  problemtypeaoptions,
  prodectoptions,
  netoptions,
  pingtaioptions,
  difoptions,
  attributeoptions,
} from "@/options/index";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import PdfLoader from "@/utils/htmlToPdfNew.js";

export default {
  components: { Editor, Toolbar },
  name: "ArticleIndex",

  props: {},
  data() {
    return {
      productModelTypeOptions,
      areaoptions,
      problemtypeaoptions,
      prodectoptions,
      netoptions,
      pingtaioptions,
      difoptions,
      attributeoptions,
      EditDNum: null,

      ifgaoji: false,
      tableHeight: window.innerHeight - 320,
      urgentStatus: [
        { status: 0, text: this.$t("di"), type: "info" }, // 1
        { status: 1, text: this.$t("zhong"), type: "primary" }, // 1
        { status: 2, text: this.$t("gao"), type: "warning" }, // 1
        { status: 3, text: this.$t("jigao"), type: "danger" }, // 1
      ],
      processStatus: [
        { status: 0, text: this.$t("kaiqi"), type: "primary" }, // 1
        { status: 1, text: this.$t("wancheng"), type: "success" }, // 1
        { status: 2, text: this.$t("daipingjia"), type: "info" }, // 1
        { status: 3, text: this.$t("yizhuanchu"), type: "warning" }, // 1
        { status: 4, text: this.$t("yiwancheng"), type: "warning" }, // 1
        { status: 5, text: this.$t("daiwancheng"), type: "info" }, // 1
      ],
      progressStatus: [
        { text: this.$t("geren"), type: "warning" }, // 0
        { text: this.$t("kefu"), type: "success" }, // 1
        { text: "FAE", type: "primary" }, // 2
        { text: this.$t("yanfa"), type: "danger" }, // 3
        { text: this.$t("xiaochengxu"), type: "success" }, // 3
        { text: this.$t("dianshang"), type: "success" }, // 3
      ],
      urgentStatustext: [
        "低", // 1
        "中", // 1
        "高", // 1
        "极高", // 1
      ],
      LeveLoptions: [
        {
          value: 0,
          label: "客户反馈问题",
        },
        {
          value: 1,
          label: "轻微客诉",
        },
        {
          value: 2,
          label: "一般客诉",
        },
        {
          value: 3,
          label: "严重客诉",
        },
      ],

      imageList: [],
      fileList: [],
      solutionlinedata: [],
      remakelinedate: [],
      remarklist: [],
      solveremark: "",
      showkf: false,
      articles: [], // 文章数据列表
      totalCount: 0, // 总数据条数
      status: null, // 查询文章的状态，不传就是全部
      channels: [], // 文章频道列表
      channelId: null, // 查询文章的频道
      rangeDate: null, // 筛选的范围日期
      loading: true, // 表单数据加载中 loading

      form: {
        creatTimeBefore: null,
        createTimeAfter: null,
        workOrderNo: null,
        createdName: null,
        processes: null,
        customerName: null,
        phoneNumber: null,
        kesudanzi: null,

        size: 20, // 每页大小
        page: 1, // 当前页码
      },

      processesoptions: [
        {
          value: null,
          label: this.$t("buxian"),
        },
        {
          value: "0",
          label: this.$t("yihcuangjian"),
        },
        {
          value: "1",
          label: this.$t("yiwancheng"),
        },
        {
          value: "0,2,3,5",
          label: this.$t("weiwancheng"),
        },
        {
          value: "3",
          label: this.$t("yizhuanchu"),
        },
        {
          value: "5",
          label: this.$t("zhuanchuhou"),
        },
      ],

      solutionlinedata: [],
      remakelinedate: [],
      planVisable: false,
      dataildata: {},
      FAEdatile: {},
      kfdatile: {},
      yanfadatile: {},
      detail_kefu: false,
      dialogImageUrl: "",
      dialogVisible: false,
      FAEpreviewList: [],
      YFpreviewList: [],
      KFpreviewList: [],
      ESpreviewList: [],
      esservedatiledata: {},
      steptype: 0,
      ifeditreport: false,
      qualitydetile: {},
      ifeditbasic: false,
      bianji: true,
      //编辑D1-D9

      ifeditD: false,
      editor: null,
      html: null,
      toolbarConfig: {
        excludeKeys: ["group-video"],
      },
      editorConfigbyDataD: {
        readOnly: true,
        autoFocus: false,
      },
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            fieldName: "file",
            server: "/api/procedure/technical-support-work-order/uploadImg",
            maxNumberOfFiles: 10,
            metaWithUrl: true,
            withCredentials: true,
            customInsert(res, insertFn) {
              // JS 语法
              // res 即服务端的返回结果

              // 从 res 中找到 url alt href ，然后插入图片
              insertFn(res.data.url, res.data.path, res.data.url);
            },
            onSuccess(file, res) {
              // JS 语法
            },
            onFailed(file, res) {
              // JS 语法
            },
          },
        },
      },
      mode: "default", // or 'simple'

      ReportInfo: {},

      DHTMLDATA: [],

      editWorderId: null,

      baseEditDate: {
        productModelType: null,
        productType: null,
        reportDate: null,
        title: null,
        type: null,
        workOrderId: null,
      },
      DdataEdit: {
        workOrderId: null,
        type: null,
        htmlContent: null,
      },
    };
  },
  computed: {
    uploadHeaders() {
      const usertoken = JSON.parse(window.localStorage.getItem("usertoken"));
      return { Authorization: usertoken };
    },
  },
  watch: {},
  created() {},
  destroyed() {},

  mounted() {
    this.form.complaintOrderNo = this.$route.query.complaintOrderNo;
    this.loadArticles(1);
    this.editorConfig.MENU_CONF.uploadImage.headers = this.uploadHeaders;
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  methods: {
    creatPDF() {
      this.bianji = false;
      const loading = this.$loading({
        lock: true,
        text: "正在解析下载PDF文件",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      setTimeout(() => {
        let ele = document.getElementById("pdfContainer");
        let deepCloneElement = ele.cloneNode(true);
        document.body.appendChild(deepCloneElement);
        let pdf = new PdfLoader(deepCloneElement, "", "itemClass");
        pdf.outPutPdfFn("产品异常8D改善报告").then((res) => {
          loading.close();
          this.bianji = true;
          document.body.removeChild(deepCloneElement);
        });
      }, 1500);
    },
    onDestroyed(editor) {
      console.log("onDestroyed", editor);
    },
    onMaxLength(editor) {
      console.log("onMaxLength", editor);
    },
    onFocus(editor) {
      console.log("onFocus", editor);
    },
    onBlur(editor) {
      console.log("onBlur", editor);
    },
    customAlert(info, type) {
      window.alert(`customAlert in Vue demo\n${type}:\n${info}`);
    },
    customPaste(editor, event, callback) {
      console.log("ClipboardEvent 粘贴事件对象", event);
      // const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
      // const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
      // const rtf = event.clipboardData.getData('text/rtf') // 获取 rtf 数据（如从 word wsp 复制粘贴）

      // 自定义插入内容
      editor.insertText("xxx");

      // 返回 false ，阻止默认粘贴行为
      event.preventDefault();
      callback(false); // 返回值（注意，vue 事件的返回值，不能用 return）

      // 返回 true ，继续默认的粘贴行为
      // callback(true)
    },

    editChange(editor) {
      // console.log("editChange", editor.getHtml());
    },

    async saveDEdit() {
      if (this.html == "<p><br></p>") {
        this.html = "<p>无</p>";
      }
      this.DdataEdit.htmlContent = this.html;
      try {
        const res = await editReportUrl(this.DdataEdit);
        if (res.data.code === 200) {
          this.DHTMLDATA[this.EditDNum - 1] = null;
          this.$message({
            message: "修改成功",
            type: "success",
          });

          this.DHTMLDATA[this.EditDNum - 1] = JSON.parse(
            JSON.stringify(this.html)
          );
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.ifeditD = false;
      }
    },
    openEditD(EditDNum) {
      this.EditDNum = EditDNum;
      this.DdataEdit.type = EditDNum;
      this.html = this.DHTMLDATA[EditDNum - 1];
      this.ifeditD = true;
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    cleardata() {
      (this.form = {
        creatTimeBefore: null,
        createTimeAfter: null,
        size: 20, // 每页大小
        page: 1, // 当前页码
      }),
        this.loadArticles();
    },
    handleUploadSuccess(response, file) {
      if (response.code === 200) {
        const { url, path } = response.data;
        this.fileList = [...this.fileList, { ...file, url, path }];
      } else {
        this.fileList = [...this.fileList];
        this.$message({
          message: response.data.msg,
          type: "error",
        });
      }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleImageRemove(file, imageList) {
      this.imageList = imageList;
    },
    /* 图片增删改查 */
    handleImgUploadSuccess(response, image) {
      if (response.code === 200) {
        const { url, path } = response.data;
        this.imageList = [...this.imageList, { ...image, url, path }];
      } else {
        this.imageList = [...this.imageList];
        this.$message({
          message: response.data.msg,
          type: "error",
        });
      }
    },

    async editReport(type) {
      this.baseEditDate.type = type;
      try {
        const res = await editReportUrl(this.baseEditDate);
        if (res.data.code === 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.ReportInfo.productModelType = this.baseEditDate.productModelType;
          this.ReportInfo.productType = this.baseEditDate.productType;
          this.ReportInfo.reportDate = this.baseEditDate.reportDate;
          this.ReportInfo.title = this.baseEditDate.title;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.ifeditbasic = false;
      }
    },

    async editreport(row, column, event) {
      this.editWorderId = row.workOrderId;
      this.ifeditreport = true;
      try {
        const res = await getReportInfoUrl({
          workOrderId: row.workOrderId,
        });
        if (res.data.code === 200) {
          this.ReportInfo = res.data.data;
          this.DHTMLDATA[0] = res.data.data.reportD1Content;
          this.DHTMLDATA[1] = res.data.data.reportD2Content;
          this.DHTMLDATA[2] = res.data.data.reportD3Content;
          this.DHTMLDATA[3] = res.data.data.reportD4Content;
          this.DHTMLDATA[4] = res.data.data.reportD5Content;
          this.DHTMLDATA[5] = res.data.data.reportD6Content;
          this.DHTMLDATA[6] = res.data.data.reportD7Content;
          this.DHTMLDATA[7] = res.data.data.reportD8Content;

          this.baseEditDate = {
            productModelType: res.data.data.productModelType,
            productType: res.data.data.productType,
            reportDate: res.data.data.reportDate,
            title: res.data.data.title,
            workOrderId: res.data.data.workOrderId,
          };
          this.DdataEdit = {
            workOrderId: res.data.data.workOrderId,
          };
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },

    editbasic() {
      this.ifeditbasic = true;
    },
    async addplan(row, column, event) {
      this.solutionlinedata = [];
      this.remakelinedate = [];

      this.planVisable = true;
      this.dataildata.workOrderId = row.workOrderId;
      // 初始化详情数据
      this.kfdatile = {};
      this.yanfadatile = {};
      this.FAEdatile = {};
      this.esservedatiledata = {};
      // this.solvform.personLiable = row.personLiable;
      try {
        const res = await SearchOneComplaintOrderUrl({
          workOrderId: row.workOrderId,
        });
        if (res.data.code === 200) {
          if (res.data.data.ecServeClientWorkOrderVo) {
            this.esservedatiledata = {
              ...res.data.data.ecServeClientWorkOrderVo,
              attachment: (
                res.data.data.ecServeClientWorkOrderVo.fileInfos || []
              ).map(({ path, url }) => ({
                path,
                url,
              })),
              image: (
                res.data.data.ecServeClientWorkOrderVo.imageInfos || []
              ).map(({ path, url }) => ({
                path,
                url,
              })),
            };

            if (this.esservedatiledata.type == 0) {
              this.esservedatiledata.type = "Unable to activate";
            } else if (this.esservedatiledata.type == 1) {
              this.esservedatiledata.type = "Offline";
            } else if (this.esservedatiledata.type == 2) {
              this.esservedatiledata.type = "APP";
            } else if (this.esservedatiledata.type == 3) {
              this.esservedatiledata.type = "Locate";
            } else if (this.esservedatiledata.type == 4) {
              this.esservedatiledata.type = "SIM card";
            } else if (this.esservedatiledata.type == 5) {
              this.esservedatiledata.type = "Package";
            } else if (this.esservedatiledata.type == 6) {
              this.esservedatiledata.type = "other";
            }

            if (this.esservedatiledata.usageScenario == 0) {
              this.esservedatiledata.usageScenario = "Vehicle";
            } else if (this.esservedatiledata.usageScenario == 1) {
              this.esservedatiledata.usageScenario = "Human";
            } else if (this.esservedatiledata.usageScenario == 2) {
              this.esservedatiledata.usageScenario = "Asset";
            } else if (this.esservedatiledata.usageScenario == 3) {
              this.esservedatiledata.usageScenario = "Other";
            }

            this.ESpreviewList = (
              res.data.data.ecServeClientWorkOrderVo.imageInfos || []
            ).map(({ url }) => url);
          }

          if (res.data.data.customerWorkOrderVo) {
            this.kfdatile = {
              ...res.data.data.customerWorkOrderVo,
              // attachment: (res.data.data.customerWorkOrderVo.fileInfos || []).map(
              //   ({ path, url }) => ({
              //     path,
              //     url,
              //   })
              // ),
              image: (res.data.data.customerWorkOrderVo.fileInfos || []).map(
                ({ path, url }) => ({
                  path,
                  url,
                })
              ),
            };
            this.KFpreviewList = (
              res.data.data.customerWorkOrderVo.fileInfos || []
            ).map(({ url }) => url);
          }

          if (res.data.data.developmentWorkOrderVo) {
            this.yanfadatile = {
              ...res.data.data.developmentWorkOrderVo,
              // attachment: (res.data.data.fileInfos || []).map(
              //   ({ path, url }) => ({
              //     path,
              //     url,
              //   })
              // ),
              // image: (res.data.data.imageInfos || []).map(({ path, url }) => ({
              //   path,
              //   url,
              // })),
            };
          }

          this.FAEdatile = {
            ...res.data.data.technicalSupportWorkOrderVo,
            attachment: (
              res.data.data.technicalSupportWorkOrderVo.fileInfos || []
            ).map(({ path, url }) => ({
              path,
              url,
            })),
            image: (
              res.data.data.technicalSupportWorkOrderVo.imageInfos || []
            ).map(({ path, url }) => ({
              path,
              url,
            })),
          };

          if (res.data.data.technicalSupportWorkOrderVo.process == 0) {
            this.steptype = 1;
          } else if (res.data.data.technicalSupportWorkOrderVo.process == 3) {
            this.steptype = 3;
          } else if (res.data.data.technicalSupportWorkOrderVo.process == 5) {
            this.steptype = 5;
          } else if (res.data.data.technicalSupportWorkOrderVo.process == 1) {
            this.steptype = 7;
          } else {
            this.steptype = 5;
          }
          // 预览图片列表
          this.YFpreviewList = (
            res.data.data.developmentWorkOrderVo?.imageInfos || []
          ).map(({ url }) => url);
          this.FAEpreviewList = (
            res.data.data.technicalSupportWorkOrderVo?.imageInfos || []
          ).map(({ url }) => url);
          if (this.FAEdatile.solution) {
            this.solutionlinedata = suanfa(this.FAEdatile.solution, "#solve#");
          }

          if (this.yanfadatile.remark) {
            this.remakelinedate = suanfa(this.yanfadatile.remark, "#reamrk#");
          }
        } else {
        }
      } catch (e) {
      } finally {
      }
    },

    onCurrentChange(page) {
      this.loadArticles(page);
    },
    handleSizeChange(val) {
      this.form.size = val;
      this.loadArticles();
    },
    async loadArticles(page = 1) {
      this.loading = true;
      try {
        const res = await getreportPage(this.form);
        if (res.data.code === 200) {
          this.articles = res.data.data.record;
          this.totalCount = res.data.data.total;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }

      // });
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped lang="less">
.header {
  float: right;
}
.filter-card {
  margin-bottom: 5px;
}

.list-table {
  margin-bottom: 10px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}
.input {
  width: 130px;
  margin-right: 10px;
}
.flexparents {
  margin-bottom: 10px;
}
.dialogfooter {
  display: flex;
  justify-content: space-between;
}
.zdhh {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.rightgap {
  margin-right: 20px;
}
.el-select {
  width: 150px;
}
.leftgap {
  margin-left: 20px;
}
.rightgap {
  margin-right: 20px;
}
.kesuitem {
  margin: 9px;
}
.chongqi {
  background-color: green;
}
.yanfatable {
  .title {
    font-weight: bolder;
    color: black;
    font-size: 16px;
  }
  .table {
    border-style: solid;
    border-width: 1px;
    border-color: #bcbeb7;
  }
}

.destitle {
  padding-left: 18px;
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 18px;
  color: rgb(0, 0, 0);
}

/deep/.el-descriptions-item__label {
  font-weight: bold !important;
  color: rgb(0, 0, 0);
}

.desbasebody {
  padding: 15px;
  border: 1px solid #0a0a0a34;
  border-radius: 25px;
  background-color: #ecf4fd;
  /deep/.el-descriptions__table {
    background-color: #ecf4fd;
  }
}

.Editbody {
  ::v-deep .el-descriptions-item__cell {
    border: 1px solid #28282a;
  }
}

.desbody {
  padding: 15px;
  border: 1px solid #0a0a0a34;
  border-radius: 25px;
}
/deep/ .w-e-text-container {
  background-color: #28282a00;
}

/deep/ .el-descriptions__body {
  background-color: #28282a00;
}
/deep/ .el-descriptions-item__cell {
  background-color: #28282a00;
}

/deep/.el-table .warning-row {
  background: oldlace;
  font-weight: bold;
  font-size: 12px;
}
/deep/.el-table .complate-row {
  background: #ffffff;
}
/deep/.el-table .white-row {
  background: #ffffff;
  font-weight: bold;
  font-size: 12px;
}
.editclass {
  border: 1px solid #65636334;
  margin-bottom: 30px;
}
.titleParents {
  display: flex;
  flex-direction: column;
  .one {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 15px;
  }
  .two {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    font-size: 40px;
    color: #000000;
  }
  .three {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
.mid {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  font-size: 40px;
}
.pdfContainer {
  width: 1000px;
  padding: 20px;
}
</style>
